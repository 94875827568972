import { FunctionComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { DateCell, MessageCell } from "./components";

export type NewsDialogProps = {
    isOpened: boolean,
    onClose: () => void,
}

export const NewsDialog: FunctionComponent<NewsDialogProps> = ({ isOpened, onClose }) => (
    <Dialog
        open={isOpened}
        onClose={onClose}
    >
        <DialogTitle >
            Novinky
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <p>Zde je historie některých vylepšení.</p>
                <br />
                <table style={{ borderSpacing: "0 8px" }}>
                    <tbody>
                        <tr>
                            <DateCell>24. 4.</DateCell>
                            <MessageCell>U jména přidán tooltip se všemi disciplínami</MessageCell>
                        </tr>
                        <tr>
                            <DateCell>17. 2.</DateCell>
                            <MessageCell>Přidán součet kilometrů do názvu stránky</MessageCell>
                        </tr>
                        <tr>
                            <DateCell>11. 2.</DateCell>
                            <MessageCell>Nově přidaní MoroSystémáci mají za svým jménem emoji 🆕</MessageCell>
                        </tr>
                        <tr>
                            <DateCell>10. 2.</DateCell>
                            <MessageCell>Jménům přidán odkaz do Strava profilu</MessageCell>
                        </tr>
                        <tr>
                            <DateCell>10. 2.</DateCell>
                            <MessageCell>Údajům v minutáích přidán popisek s přepočtem do hodin</MessageCell>
                        </tr>
                        <tr>
                            <DateCell>1. 2.</DateCell>
                            <MessageCell>Spuštění výzvy</MessageCell>
                        </tr>
                    </tbody>
                </table>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
);
