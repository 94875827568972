import { FunctionComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export type UpdateDialogProps = {
    isOpened: boolean,
    onClose: () => void,
}

export const UpdateDialog: FunctionComponent<UpdateDialogProps> = ({ isOpened, onClose }) => (
    <Dialog
        open={isOpened}
        onClose={onClose}
    >
        <DialogTitle >
            Aktualizace dat
        </DialogTitle>
        <DialogContent>
            <DialogContentText color="#000">
                Proč tak staré údaje? Strava umožňuje 1 000 dotazů denně, na každého uživatele je nutný alespoň jeden dotaz.
                Se započtením aktualizace přihlašovacích tokenů a předchozích měsíců je možných přibližně 6 aktualizací denně.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
);
