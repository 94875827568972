export type DisciplineData = {
    title: Title,
    unit: string,
    leaderboard: LeaderboardData[],
}

const titleList = ["Kolo", "Běh", "Chůze", "Plavání", "Fitness", "Bruslení"] as const;
export type Title = typeof titleList[number];

export const parseTitle = (text: unknown): Title | undefined => {
    const title = titleList.find((title) => title === text);
    if (title !== undefined) {
        return title;
    }
    return undefined;
}

/** Keep synchornized with backend app */
export enum ProblemCode {
    Code = "code",
    Scope = "scope",
    AlreadyExist = "already-exist",
    SaveUser = "save-user",
    GetUsers = "get-users",
    TokenRequest = "token-request",
    GetAthleteDetail = "get-athlete-detail",
    MissingId = "missing-id",
    UnknownId = "unknown-id",
    SaveRefreshToken = "save-refresh-token",
    RefreshToken = "refresh-token",
    GetUser = "get-user",
    GetUserIds = "get-user-ids",
}

/** Keep synchornized with backend app */
export enum SuccessCode {
    Added = "added",
}

export type UserResultData = {
    addedTimestamp: number | null,
    id: number,
    firstname: string,
    lastname: string,
    runKm: number,
    walkKm: number,
    rideKm: number,
    swimKm: number,
    fitnessMin: number,
    inlineKm: number,
}

export type LocalResultsData = {
    timestamp: number,
    data: {
        [userId: string]: {
            [month: number]: UserResultData,
        },
    },
}

export type LeaderboardData = {
    id: number,
    addedTimestamp: number | null,
    firstname: string,
    lastname: string,
    score: number,
}
