import { FunctionComponent } from "react";

import { UNBREAKING_SPACE } from "../utils";
import { Spacer } from "./Spacer";

export const ContactFooter: FunctionComponent = () => (
    <div>
        S láskou
        {UNBREAKING_SPACE}
        <a
            style={{ color: "black", textDecoration: "underline" }}
            href="https://pavelpo.cz/kontakty"
            target="_blank"
            rel="noreferrer"
        >
            Pavel
        </a>
        <Spacer />
    </div>
);
