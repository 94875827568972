import { FunctionComponent } from "react";
import { useLocation } from "react-router";
import { ContactFooter } from ".";

import { ProblemCode } from "../types";
import { BackLink } from "./BackLink";
import { ColoredTitle } from "./ColoredTitle";
import { Spacer } from "./Spacer";
import { HomeLink } from "./HomeLink";

const CONTANT_ME_TEXT = "Kontaktuj mě, prosím, odkazem v patičce.";

export const Error: FunctionComponent = () => {
    const location = useLocation();
    const reason = location.pathname.split("/").reverse()[0];

    const getMessage = () => {
        switch (reason) {
            case ProblemCode.Code: return `Nepodařilo se mi získat uživatelský klíč. ${CONTANT_ME_TEXT}`;
            case ProblemCode.Scope: return `Zaškrtni prosím obě políčka – jinak se k tvým aktivitám nedostanu. Zkus to, prosím, znovu.`;
            case ProblemCode.AlreadyExist: return `Už jsi součástí týmu. Není tomu tak? ${CONTANT_ME_TEXT}`;
            case ProblemCode.SaveUser: return `Nepovedlo se mi uložit tvoje údaje. ${CONTANT_ME_TEXT}`;
            case ProblemCode.GetUsers: return `Něco jsem pokazil v databázi, nemůžu tě najít. ${CONTANT_ME_TEXT}`;
            case ProblemCode.TokenRequest: return `Nepodařilo se mi od Stravy získat přístupový klíč. ${CONTANT_ME_TEXT}`;
            case ProblemCode.GetAthleteDetail: return `Nepodařilo se mi ze Stravy získat tvoje aktivit. ${CONTANT_ME_TEXT}`;
            case ProblemCode.MissingId: return `Nepodařilo se mi získat tvůj jedinečný identifikátor. ${CONTANT_ME_TEXT}`;
            case ProblemCode.UnknownId: return `Nemůžu najít tvůj jedinečný identifikátor. ${CONTANT_ME_TEXT}`;
            case ProblemCode.SaveRefreshToken: return `Nepodařilo se mi uložit tvůj obnovovací klíč. ${CONTANT_ME_TEXT}`;
            case ProblemCode.RefreshToken: return `Nepodařilo se mi získat tvůj obnovovací klíč. ${CONTANT_ME_TEXT}`;
            case ProblemCode.GetUser: return `Nemůžu v databázi najít tvoje údaje. ${CONTANT_ME_TEXT}`;
            case ProblemCode.GetUserIds: return `Nemůžu v databázi najít identifikátory všech uživatelů. ${CONTANT_ME_TEXT}`;
            default: return "Neznámá chyba. Zkus to prosím znovu – ať už teď nebo později.";
        }
    }

    return (
        <div className="content">
            <Spacer />
            <ColoredTitle first="Nastala" second="chyba" />
            <Spacer />
            {getMessage()}
            <Spacer />
            {reason === ProblemCode.AlreadyExist ? (
                <HomeLink />
            ) : (
                <BackLink />
            )}
            <Spacer />
            <ContactFooter />
        </div>
    );
};
