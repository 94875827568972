import { DisciplineData, LeaderboardData, parseTitle, Title, UserResultData } from "./types";

export const CLIENT_ID = "70575";
export const FIREBASE_FUNCTIONS_URL = "https://us-central1-challenge-tracker-e255b.cloudfunctions.net";
export const AUTHORIZE_URL = `${FIREBASE_FUNCTIONS_URL}/authorize`;
export const STRAVA_LOGIN_URL = `https://www.strava.com/oauth/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${AUTHORIZE_URL}&approval_prompt=force&scope=activity:read_all`;
export const EM_SPACE = " ";
export const EN_SPACE = " ";
export const THIN_SPACE = " ";
export const GET_URL = `${FIREBASE_FUNCTIONS_URL}/get`;
export const PRIMARY_COLOR = "#fcbf00";
export const UNBREAKING_SPACE = " ";
export const DISCIPLINE_LOCAL_STORAGE_KEY = "discipline";
export const SCORE_THREASHOLD = 0.001;
export const MINUTES_IN_HOUR = 60;
export const MILLISECONDS_IN_DAY = 24 * MINUTES_IN_HOUR * 60 * 1000;
export const KM_UNIT = "km";

export const formatNumber = (value: number, digits: number = 2) => value.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits })

export const sort = (leaderboardDataList: LeaderboardData[]) => leaderboardDataList
    .sort((a, b) => a.lastname.localeCompare(b.lastname))
    .sort((a, b) => a.firstname.localeCompare(b.firstname))
    .sort((a, b) => b.score - a.score);

export const convertGetUserDataListToDisciplineDataList = (data: UserResultData[]): DisciplineData[] => ([
    {
        title: "Kolo",
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.rideKm,
        }))),
    },
    {
        title: "Běh",
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.runKm,
        }))),
    },
    {
        title: "Chůze",
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.walkKm,
        }))),
    },
    {
        title: "Plavání",
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.swimKm,
        }))),
    },
    {
        title: "Fitness",
        unit: "min",
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.fitnessMin,
        }))),
    },
    {
        title: "Bruslení",
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.inlineKm,
        }))),
    },
]);

export const getDefaultDisciplineTitle = (): Title => {
    const value = localStorage.getItem(DISCIPLINE_LOCAL_STORAGE_KEY);

    if (value === null) {
        return "Kolo";
    }

    return parseTitle(value) ?? "Kolo";
}

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const formatTime = (timestamp: number) => (new Date(timestamp)).toLocaleTimeString();

export const hasHourTooltip = (unit: string, score: number) => unit === "min" && score >= SCORE_THREASHOLD;

export const getMinuteCellTextDecoration = (unit: string, score: number) => hasHourTooltip(unit, score) ? "underline dotted rgba(0, 0, 0, 0.2)" : undefined;

export const getDisciplineScoreSum = (selectedDiscipline: DisciplineData) => selectedDiscipline.leaderboard.map(({ score }) => score).reduce((a, b) => a + b, 0);

export const getMinuteCellTitle = (unit: string, score: number): string | undefined => {
    if (!hasHourTooltip(unit, score)) {
        return undefined;
    }
    const hours = Math.floor(score / MINUTES_IN_HOUR);
    switch (hours) {
        case 0: return "Pod 1 hodinu 💪";
        case 1: return "Přes 1 hodinu 💪";
        case 2: case 3: case 4: return `Přes ${hours} hodiny 💪`;
        default: return `Přes ${hours} hodin 💪`;
    }
}

export const getActualMilliseconds = () => Date.now();
