import { FunctionComponent } from "react";
import { HomeLink } from ".";

import { ColoredTitle } from "./ColoredTitle";
import { Spacer } from "./Spacer";

export const Success: FunctionComponent = () => (
    <div className="content">
        <Spacer />
        <ColoredTitle first="úspěšné" second="přidání" />
        <Spacer />
        <h3>Vítej v týmu, tvé jméno a údaje se v tabulce ukážou po příští aktualizaci dat</h3>
        <Spacer />
        <HomeLink />
    </div>
);
