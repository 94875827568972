import { FunctionComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export type InfoDialogProps = {
    isOpened: boolean,
    onClose: () => void,
}

export const InfoDialog: FunctionComponent<InfoDialogProps> = ({ isOpened, onClose }) => (
    <Dialog
        open={isOpened}
        onClose={onClose}
    >
        <DialogTitle >
            O co jde?
        </DialogTitle>
        <DialogContent>
            <DialogContentText color="#000">
                Máme rádi sport, data a technologie. Již několik let závodíme v celofiremní výzvě, kdo nasbírá během roku nejvíc kilometrů.
                Trackujeme si kolik naběháme, uplaveme, ujedeme na kole nebo na bruslích. Nejde nám o to najít toho největšího borce z nás.
                Na konci roku kilometry z celé firmy sečteme a výslednou sumu věnujeme na charitu nebo podpoříme vybraný projekt.
                <br />
                <br />
                <a
                    href="https://www.morosystems.cz/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }}
                >
                    www.morosystems.cz
                </a>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
);
