import { FunctionComponent, useEffect, useState } from "react";
import axios from "axios";
import { Button, IconButton, Typography, Tooltip } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import {
    convertGetUserDataListToDisciplineDataList,
    DISCIPLINE_LOCAL_STORAGE_KEY,
    EM_SPACE,
    EN_SPACE,
    formatNumber,
    getActualMilliseconds,
    getDefaultDisciplineTitle,
    getDisciplineScoreSum,
    getMinuteCellTextDecoration,
    getMinuteCellTitle,
    GET_URL,
    KM_UNIT,
    MILLISECONDS_IN_DAY,
    PRIMARY_COLOR,
    SCORE_THREASHOLD,
    THIN_SPACE,
    UNBREAKING_SPACE,
} from "../utils";
import { DisciplineData, LocalResultsData, Title, UserResultData } from "../types";
import { Spacer } from "./Spacer";
import { ContactFooter } from "./ContactFooter";
import { ColoredTitle } from "./ColoredTitle";
import { StravaLogo } from "./StravaLogo";
import { Loading } from "./Loading";
import { Error } from "./Error";
import { IconButtonHitArea } from "./IconButtonHitArea";
import { UpdateDialog } from "./UpdateDialog";
import { UpdateFooter } from "./UpdateFooter";
import { InfoDialog } from "./InfoDialog";
import { NewsDialog } from "./NewsDialog";
import { LoginDialog } from "./LoginDialog";

export const Content: FunctionComponent = () => {
    const [disciplineDataList, setDisciplineDataList] = useState<DisciplineData[]>([]);
    const [isLoginDialogOpened, setIsLoginDialogOpened] = useState(false);
    const [isInfoDialogOpened, setIsInfoDialogOpened] = useState(false);
    const [isNewsDialogOpened, setIsNewsDialogOpened] = useState(false);
    const [isUpdateDialogOpened, setIsUpdateDialogOpened] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDisciplineTitle, setSelectedDisciplineTitle] = useState<Title | undefined>(undefined);
    const [updatedTimestamp, setUpdatedTimestamp] = useState<number | undefined>(undefined);

    const selectDisciplineTitle = (disciplineTitle: Title) => {
        setSelectedDisciplineTitle(disciplineTitle);
        localStorage.setItem(DISCIPLINE_LOCAL_STORAGE_KEY, disciplineTitle);
    }

    const sumData = (data: LocalResultsData): UserResultData[] => Object.values(data.data)
        .map((monthDataList) => {
            const valuableMonthDataList = Object.values(monthDataList).filter((monthData) => monthData !== null);
            return valuableMonthDataList.slice(1).reduce((acc, value) => ({
                ...acc,
                runKm: acc.runKm + value.runKm,
                walkKm: acc.walkKm + value.walkKm,
                rideKm: acc.rideKm + value.rideKm,
                swimKm: acc.swimKm + value.swimKm,
                fitnessMin: acc.fitnessMin + value.fitnessMin,
                inlineKm: acc.inlineKm + value.inlineKm,
            }), valuableMonthDataList[0]);
        });

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        (async () => {
            try {
                const localResultData: LocalResultsData = await (await axios({
                    method: "get",
                    url: GET_URL,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                })).data.data;
                setUpdatedTimestamp(localResultData.timestamp);
                setDisciplineDataList(convertGetUserDataListToDisciplineDataList(sumData(localResultData)));
                selectDisciplineTitle(getDefaultDisciplineTitle());
                setIsLoading(false);
            } catch {
                setIsError(true);
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (disciplineDataList.length === 0) {
            document.title = "MoroKilometry";
            return;
        }
        const distanceTotal = disciplineDataList
            .filter((disciplineData) => disciplineData.unit === KM_UNIT)
            .map((disciplineData) => getDisciplineScoreSum(disciplineData))
            .reduce((a, b) => a + b, 0);
        document.title = `MoroKilometry${EN_SPACE}–${EN_SPACE}${formatNumber(distanceTotal, 0)} km`;
    }, [disciplineDataList]);

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return <Error />;
    }

    if (disciplineDataList === undefined) {
        return <Error />
    }

    const selectedDiscipline = disciplineDataList.find((disciplineData) => disciplineData.title === selectedDisciplineTitle);

    if (selectedDiscipline === undefined) {
        return <Error />;
    }

    const getPreviousDisciplineTitle = (): Title => {
        const index = disciplineDataList.findIndex((discipline) => discipline.title === selectedDisciplineTitle);

        if (index === 0) {
            return disciplineDataList[disciplineDataList.length - 1].title;
        } else {
            return disciplineDataList[index - 1].title;
        }
    }

    const selectPreviousDiscipline = () => selectDisciplineTitle(getPreviousDisciplineTitle());

    const getNextDisciplineTitle = (): Title => {
        const index = disciplineDataList.findIndex((discipline) => discipline.title === selectedDisciplineTitle);

        if (index === disciplineDataList.length - 1) {
            return disciplineDataList[0].title;
        } else {
            return disciplineDataList[index + 1].title;
        }
    }

    const selectNextDiscipline = () => selectDisciplineTitle(getNextDisciplineTitle());

    return (
        <div className="content">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", margin: 16 }}>
                <Button
                    variant="outlined"
                    disableElevation={true}
                    onClick={() => setIsInfoDialogOpened(true)}
                >
                    O co jde?
                </Button>
                <div style={{ marginLeft: 16 }}>
                    <Button
                        variant="outlined"
                        disableElevation={true}
                        onClick={() => setIsNewsDialogOpened(true)}
                    >
                        Novinky
                    </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "end", flex: 1 }}>
                    <Button
                        variant="contained"
                        disableElevation={true}
                        onClick={() => setIsLoginDialogOpened(true)}
                    >
                        Přidat se
                    </Button>
                </div>
            </div>
            <Spacer />
            <ColoredTitle first="Moro" second="Kilometry" />
            <Spacer />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <h1 style={{ textDecoration: "underline", textDecorationColor: PRIMARY_COLOR, opacity: 0.05 }}>{getPreviousDisciplineTitle()[getPreviousDisciplineTitle().length - 2]}</h1>
                <h1 style={{ textDecoration: "underline", textDecorationColor: PRIMARY_COLOR, opacity: 0.1 }}>{getPreviousDisciplineTitle()[getPreviousDisciplineTitle().length - 1]}</h1>
                <IconButton color="primary" onClick={selectPreviousDiscipline}>
                    <IconButtonHitArea />
                    <ChevronLeft />
                </IconButton>
                <h1 style={{ textDecoration: "underline", textDecorationColor: PRIMARY_COLOR }}>{selectedDiscipline.title}</h1>
                <IconButton color="primary" onClick={selectNextDiscipline}>
                    <IconButtonHitArea />
                    <ChevronRight />
                </IconButton>
                <h1 style={{ textDecoration: "underline", textDecorationColor: PRIMARY_COLOR, opacity: 0.1 }}>{getNextDisciplineTitle()[0]}</h1>
                <h1 style={{ textDecoration: "underline", textDecorationColor: PRIMARY_COLOR, opacity: 0.05 }}>{getNextDisciplineTitle()[1]}</h1>
            </div>
            <Spacer />
            <table className="table">
                <tbody>
                    <tr className="header-line">
                        <td style={{ textAlign: "start", paddingRight: 0 }}>#</td>
                        <td style={{ textAlign: "start" }}>Jméno</td>
                        <td style={{ textAlign: "end" }}>Skóre</td>
                    </tr>
                    {selectedDiscipline.leaderboard.map((member, index) => (
                        <tr key={member.id} className="line">
                            <td style={{ textAlign: "start", paddingRight: 0 }}>{index + 1}</td>
                            <td style={{ textAlign: "start" }}>
                                <Tooltip title={
                                    <>
                                        {disciplineDataList.map((disciplineData) => {
                                            const leaderboardData = disciplineData.leaderboard.find((leaderboardData) => leaderboardData.id === member.id);
                                            if (leaderboardData !== undefined) {
                                                return (
                                                    <>
                                                        <Typography
                                                            variant="caption"
                                                            color={leaderboardData.score < SCORE_THREASHOLD ? "rgba(255, 255, 255, 0.5)" : "white"}
                                                            key={disciplineData.title}
                                                            style={{ display: "flex", flexDirection: "row" }}
                                                        >
                                                            {disciplineData.title}{EM_SPACE}<span style={{ flex: 1 }} />{formatNumber(leaderboardData.score)}{THIN_SPACE}{disciplineData.unit}
                                                        </Typography>
                                                    </>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </>
                                } followCursor={true}>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.strava.com/athletes/${member.id}`}
                                    >
                                        {member.firstname}
                                        {UNBREAKING_SPACE}
                                        <span style={{ color: PRIMARY_COLOR }}>
                                            {member.lastname}
                                        </span>
                                    </a>
                                </Tooltip>
                                {member.addedTimestamp && member.addedTimestamp > (getActualMilliseconds() - 3 * MILLISECONDS_IN_DAY) ? (
                                    <>
                                        {UNBREAKING_SPACE}
                                        <span title="V týmu méně než 3 dny 👋">🆕</span>
                                    </>
                                ) : null}
                                {selectedDiscipline.unit === KM_UNIT && member.score > 10000 ? (
                                    <>
                                        {UNBREAKING_SPACE}
                                        <span title="To je hrozně móc! 😱">🤯</span>
                                    </>
                                ) : null}
                            </td>
                            <td
                                style={{
                                    textAlign: "end",
                                    textDecoration: getMinuteCellTextDecoration(selectedDiscipline.unit, member.score),
                                }}
                                title={getMinuteCellTitle(selectedDiscipline.unit, member.score)}
                            >
                                {member.score < SCORE_THREASHOLD ? (
                                    <span style={{ opacity: 0.2 }}>–</span>
                                ) : (
                                    formatNumber(member.score)
                                )}
                                {UNBREAKING_SPACE}
                                <span style={{ color: PRIMARY_COLOR }}>
                                    {selectedDiscipline.unit}
                                </span>
                            </td>
                        </tr>
                    ))}
                    <tr style={{ height: 7 }} />
                    <tr>
                        <td style={{ height: 1.9, backgroundColor: "rgba(0,0,0,0.87)", padding: 0, opacity: 0.2 }} colSpan={3} />
                    </tr>
                    <tr style={{ height: 5 }} />
                    <tr className="line">
                        <td style={{ textAlign: "start", paddingRight: 0 }}></td>
                        <td style={{ textAlign: "start" }}></td>
                        <td
                            style={{
                                textAlign: "end",
                                textDecoration: getMinuteCellTextDecoration(selectedDiscipline.unit, getDisciplineScoreSum(selectedDiscipline)),
                            }}
                            title={getMinuteCellTitle(selectedDiscipline.unit, getDisciplineScoreSum(selectedDiscipline))}
                        >
                            {formatNumber(getDisciplineScoreSum(selectedDiscipline))}
                            {UNBREAKING_SPACE}
                            <span style={{ color: PRIMARY_COLOR }}>
                                {selectedDiscipline.unit}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Spacer />
            {updatedTimestamp ? (
                <UpdateFooter
                    timestamp={updatedTimestamp}
                    onOpenDescription={() => setIsUpdateDialogOpened(true)}
                />
            ) : null}
            <Spacer />
            <StravaLogo />
            <ContactFooter />
            <Spacer />
            <Spacer />
            <LoginDialog isOpened={isLoginDialogOpened} onClose={() => setIsLoginDialogOpened(false)} />
            <UpdateDialog isOpened={isUpdateDialogOpened} onClose={() => setIsUpdateDialogOpened(false)} />
            <InfoDialog isOpened={isInfoDialogOpened} onClose={() => setIsInfoDialogOpened(false)} />
            <NewsDialog isOpened={isNewsDialogOpened} onClose={() => setIsNewsDialogOpened(false)} />
        </div >
    );
};
