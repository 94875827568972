import { FunctionComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { STRAVA_LOGIN_URL } from "../utils";

export type LoginDialogProps = {
    isOpened: boolean,
    onClose: () => void,
}

export const LoginDialog: FunctionComponent<LoginDialogProps> = ({ isOpened, onClose }) => (
    <Dialog
        open={isOpened}
        onClose={onClose}
    >
        <DialogTitle >
            Zveřejnění údajů
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <ul>
                    <li>Výzva je určena pouze pro MoroSystémáky</li>
                    <li>Nahrání dat z předchozích měsíců může trvat až jeden den</li>
                    <li>Přidáním souhlasíš se zobrazením tvého jména a sportovních výkonů z aplikace Strava</li>
                </ul>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Zrušit</Button>
            <Button onClick={() => {
                onClose();
                window.location.href = STRAVA_LOGIN_URL;
            }}>
                Souhlasím
            </Button>
        </DialogActions>
    </Dialog>
);
